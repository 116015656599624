import React, { Component } from "react";
import Ilustration from "./assets/ilustration.png";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "https://alaminpuloerang.sch.id",
    };
  }

  render() {
    return (
      <main className="w-screen h-screen overflow-hidden bg-[#272262] px-20 py-10">
        <div className="flex flex-col xl:flex-row md:flex-col items-center h-full">
          {/* Left Side */}
          <div>
            <img
              src={Ilustration}
              alt="maintenance-illustration"
              className="w-[388px] md:w-[488px] xl:w-[688px] object-cover"
            />
          </div>

          {/* Right Side */}
          <div className="mt-10 md:mt-20 xl:mt-0 lg:ml-0 xl:ml-20 text-center sm:text-start md:text-start lg:text-start xl:text-start">
            <h5 className="font-space text-2xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl text-indigo-200 font-bold">
              Maintenance Mode
            </h5>
            <div className="flex items-center mt-4">
              <div className="h-24 w-[1.5px] rounded-full bg-indigo-200"></div>
              <div>
                <span className="w-[431px] block text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl font-inter text-indigo-200 ml-3">
                  We are adding new features: <br />
                  it will be operational really soon
                </span>
                <span className="w-[431px] block text-sm sm:text-lg md:text-lg lg:text-lg xl:text-lg italic font-medium mt-3 font-inter text-indigo-200 ml-3">
                  May the force be with you
                </span>
              </div>
            </div>
            <a
              href={this.state.url}
              className="text-sm sm:text-lg md:text-lg lg:text-lg xl:text-lg font-inter text-indigo-200 mt-10 underline block"
            >
              Visit our official web here
            </a>
          </div>
        </div>
      </main>
    );
  }
}

export default App;
